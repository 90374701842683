import store from '@/state/store.js';
import MainUserComponent from './mainsUsersComponent';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Registered Users',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'individual-users',
  components: {
    MainUserComponent
  },
  data() {
    return {
      parent: {
        name: 'all',
        storeDataName: 'users',
        dispatchName: 'admin/users/allUsers',
        searchDispatchName: 'admin/users/searchAllUsers'
      }
    };
  },
  methods: {},
  computed: {},
  mounted() {
    // store.dispatch('admin/users/allUsers')
    store.dispatch('admin/reporting/getChartAccounts');
  }
};